export const videoArray = [{
    id: 'myPlayer',
    name: '十八湾茶叶公司',
    county: '沅陵',
    countyCode: '431222',
    lat: 28.51930618,
    lon: 110.66699282,
    icon: '/static/monitor/sub_shexiangtou.png',
    url: 'https://cmgw-vpc.lechange.com:8890/LCO/9D0AE86PAJ1ED97/0/1/20241105T025103/226ff6e6009a495a850ec064a9a369d0.m3u8?proto=https&source=open',
    nearStationId: '00081',
}]